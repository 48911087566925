@font-face {
  font-family: 'Gang of Three' ;
  src: url('./assets/font/GangOfThree.ttf');
  font-display: block;
}


body {
  margin: 0;
  background-color: #070713;
  background-image: url("./assets/img/bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 150px;
}

.train {
}

.dogeo-title {
  font-family: 'Gang of Three', sans-serif;
  color: white;
  position: absolute !important;
  top: -112px;
  padding-left: 6px !important;
  width: 174px !important;
  white-space: nowrap;
  font-size: 40px;
  left: 0;
  right: 0;
  margin: auto;
}

.dogeo-title span {
  color: #2825BF;
}

.init-teko-font {
  font-family: 'Teko', sans-serif;
  font-weight: 500;
  color: transparent;
  position: fixed;
  z-index: -1;
}

@media (max-width: 780px) {
  .dogeo-title {
    top: -101px;
  }
}

@media (max-width: 350px) {
  .dogeo-title {
    transform: translateY(3vw);
  }
}
