#root.noMotion .header-container {
  background-color: #070713;
  position: relative;
  z-index: 2;
}
#root.noMotion .header-container::after {
  content: "";
  position: absolute;
  width: 200vw;
  left: -100%;
  background-color: #070713;
  top: 0;
  bottom: 0;
  z-index: 1;
}
#root.noMotion .footer-container {
  z-index: 2;
}

#root.noMotion {
  min-height: initial;
  height: 100vh;
  /*max-height: 100vh;*/
  overflow: hidden;
}

@media (max-width: 780px) {
#root.noMotion {
    max-height: initial;
    min-height: 880px;
  }
}

#root.noMotion .japanese__bg {
    position: absolute;
    transform: translate(-50.2%,-54.5%);
    width: 1481px;
    height: 840px;
    top: 50%;
    left: 50%;
    z-index: -2;
    filter: brightness(1.9);
    user-select: none;
}

#root.noMotion .training__container:before {
    content: "";
    position: absolute;
    width: 970px;
    top: -390px;
    left: -117.4px;
    height: 1007px;
    z-index: -1;
    background: url('../../../assets/img/bg/House-8.png') no-repeat center;
    background-size: contain;
    filter: brightness(1.9);
    pointer-events: none;
}

@media (max-width: 768px) {
    #root.noMotion .training__container:before {
        background: url('../../../assets/img/bg/HouseMobile.png') no-repeat center;
        width: 200%;
        top: 36%;
        left: 53%;
        height: 146%;
        transform: translate(-50%, -50%);
        background-size: 101%;
    }
}

#root.noMotion .dog__nft span:not(.dog-id)::before {
    content: "";
    height: 80px;
    top: calc(100% + 25.7px);
    position: absolute;
    left: -4px;
    right: -4px;
    backface-visibility: visible;
    background: no-repeat url("../../../assets/img/corner.svg");
    background-size: contain;
    width: calc(100% + 8px);
}


@media(min-width: 1481px), (min-height: 840px) {
    #root.noMotion .japanese__bg {
    height: initial;
    width: initial;
    min-width: 101vw;
    min-height: 101vh;
  }
}
